:root {
	font-size: 62.5%;
	--black: #222026;
	--white: #fbf9f9;
	--offwhite: #efece6;
	--green: #30bba5;
	--yellow: #f3c67e;
	--blue: #00576f;
	--red: #e55a65;

	/*font sizes*/
	--huge: 7rem;
	--big: 4rem;
	--heading: 2.4rem;
	--subheading: 2.2rem;
	--subsubheading: 1.8rem;
	--dft: 1.6rem;
	--small: 1.4rem;
	--tiny: 1rem;

	--h1: 2.4rem;
	--h2: 2.2rem;
	--h3a:2.0rem;
	--h3: 1.8rem;
	--h4: 1.6rem;
	--h5: 1.4rem;
	--h6: 1rem;

	--cursor: url('images/icons/hover-icon.png');

	--default-font: 'aaux-next', sans-serif;
	--heading-font: 'interstate-condensed', sans-serif;

	/* nav */
	--nav-height: 68px;
	--desktop-nav-height: 160px;
	--collapsed-nav-height: 14px;
	--collapsed-top: calc(var(--collapsed-nav-height) - var(--nav-height));
	--collapsed-top-desktop: calc(var(--collapsed-nav-height) - var(--desktop-nav-height));
}

/*colors*/
.black { background: var(--black); }

.white { background: var(--white); }

.offwhite { background: var(--offwhite); }

.green { background: var(--green); }

.yellow { background: var(--yellow); }

.blue { background: var(--blue); }

.red { background: var(--red); }


.button{
	background: none;
	text-transform: uppercase;
	transition: all 0.3s;
}
.button:hover{
	cursor: var(--cursor) 10 10, auto !important;
	color: var(--white);
	border-color: var(--black) !important;
}

.small-button{
	padding: 10px 20px;
	font-size: var(--tiny);
}
.button.green{
	border: 2px solid var(--green);
}
.button.green:hover {
	background: var(--green);
}
.button.red{
	border: 2px solid var(--red);
}
.button.red:hover {
	background: var(--red);
}