/*font*/

.hamburger {
	color: var(--white);
}

.nav-list {
	color: var(--offwhite);
	text-transform: uppercase;
	font-size: var(--huge);
	line-height: var(--huge);
}

.nav-notification-text {
	color: var(--black);
	font-family: 'aaux-next', sans-serif;
	font-size: var(--tiny);
}

.header-cart-btn {
	text-transform: capitalize;
	font-family: "Open Sans";
}

.header-cart-btn, .nav-item-link {
	text-align: right;
	color: var(--offwhite);
	transition: all 0.2s;
}

.nav-item-link {
	font-weight: bold;
}


/*display*/
.nav-wrapper {
	display: flex;
	height: var(--nav-height);
	justify-content: space-between;
	flex-direction: row;
	align-items: stretch;
}

.img-nav-link {
	display: inline-flex;
	width: calc(100% - var(--nav-height));
	height: var(--nav-height);
	align-content: center;
	align-items: center;
	background: var(--offwhite);
	transition: width 0.1s;
}

.hamburger {
	display: block;
	width: var(--nav-height);
	height: 100%;
	background: var(--green) !important;
}

.collapse .img-nav-link {
	transition: width 0;
}

.collapse .hamburger {
	width: 100%;
}

.collapse .img-nav-link, .collapse .subnav-wrapper {
	width: 50%;
}

.subnav-wrapper {
	z-index: 1;
}

.img-nav.logo {
	padding: 0 10px;
	box-sizing: content-box;
}

.img-nav.wordmark {
	max-width: 150px;
	height: 28px;
}

.nav-list, .nav-item {
	margin: 0;
	padding: 0;
}

.nav-list {
	padding-top: 20px;
}

.nav-item {
	display: block;
}

.nav-list {
	position: absolute;
	top: -100vh;
	transition: top 0.5s;
	transition-timing-function: cubic-bezier(0.79, 0.11, 0.37, 0.85);
	background: var(--green);
	width: 100%;
	height: calc(100vh - var(--nav-height));
	left: 0;
	display: block;
	overflow: scroll;
}

.nav-list.open {
	top: var(--nav-height);
}

.header-cart-btn, .nav-item-link {
	display: block;
	text-align: right;
	padding-right: 30px;
}

.header-cart {
	display: flex;
	justify-content: flex-end;
}

.hamburger:after{
	content: ' ';
	background-image: url(images/icons/hamburger.svg);
	display: block;
	width: 68px;
	height: 68px;
	position: absolute;
	top: 0;
	left: 0;
	background-size: 28px;
	background-repeat: no-repeat;
	background-position: center;
}

.hamburger.open:after{
	background-image: url(images/icons/close.svg);
}

.hamburger, .img-nav-link {
	position: relative;
	z-index: 2;
}

.header-nav {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 10;
	transform: translateY(0);
	will-change: transform;
	transition: transform 0.5s, background-color 0.5s;
	background-color: var(--offwhite);
}

.header-nav.collapse {
	transform: translateY(var(--collapsed-top));
}

.collapse .img-nav-link {
	background: var(--white);
}

.collapse .img-nav-link, .collapse .hamburger {
	z-index: -1;
}

.nav-notification:after {
	display: block;
	content: " ";
	background: var(--white);
	width: 18px;
	height: 17px;
	position: absolute;
	bottom: 18px;
	left: 12px;
	transform: skewX(-40deg) rotate(-17deg);
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	z-index: 2;
}

.gus-notification {
	position: absolute;
	right: 200px;
	top: 50px;
}

.nav-notification {
	transform: translateZ(0);
	top: -1000px;
	position: absolute;
	z-index: -10;
	right: 20%;
	background: var(--white);
	padding: 10px;
	border-radius: 50%;
	width: 200px;
	height: 150px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	border-left: 2px solid black;
	border-bottom: 2px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	opacity: 0;
	transition: opacity 0.2s, top 0.2s;
}

.nav-notification-text {
	margin: 0 20px;
	padding: 0;
	position: relative;
	z-index: 10;
}

.helper-arrow {
	display: block;
	position: absolute;
	top: 32px;
	left: -50px;
	width: 40px;
	height: 5px;
	background: black;
	z-index: 100;
	transition: left 0.5s 0.3s;
}

.helper-arrow:after {
	display: block;
	content: ' ';
	position: absolute;
	width: 20px;
	height: 20px;
	border: 5px solid black;
	right: 0;
	transform: rotate(45deg);
	top: -8px;
	border-left: 0;
	border-bottom: 0;
}

.helper-arrow.show {
	left: calc(100% - var(--nav-height) - 45px);
}

.helper-arrow.hide {
	opacity: 0 !important;
	transition: left 0s;
}

.nav-notification.show {
	opacity: 1;
	z-index: 100;
	top: auto;
	display: flex !important;
}

.nav-item-link:hover{
	background: var(--black);
	color: var(--offwhite) !important;
}

.img-nav.logo{
	width:75px;
	height:75px;
}
a:hover{
	cursor: var(--cursor) 15 10, auto;
}


@media all and (min-width: 769px){
	.header-nav{
		/*position: static;*/
	}
	.header-nav.collapse{
		transform: translateY(var(--collapsed-top-desktop));
		background-color: var(--green);
	}
	.collapse .img-nav-link, .collapse .subnav-wrapper{
		width: auto;
	}
	.nav-wrapper{
		height: auto;
		flex-direction: column;
		align-items: center;
	}
	.img-nav-link{
		margin: 30px 30px 5px;
		background: var(--offwhite);
	}

	.img-nav-link{
		width: 200px;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.img-nav-link:hover .vertical-wordmark{
		display: block;
		max-width: 100%;
		height: 75px;
	}
	.img-nav-link:hover .logo{
		display: none;
	}

	.img-nav-link .wordmark{
		display: none;
	}


	.hamburger{
		display: none !important;
	}
	.nav-list{
		position: relative;
		top: auto;
		background: inherit;
		height: auto;
		display: flex;
		font-size: var(--dft);
		line-height: var(--dft);
		font-family: var(--default-font);
		padding-top: 0;
	}
	.nav-item-link{
		color: #4d4d4d;
		padding: 15px;
		margin-bottom: 10px;
	}
	.nav-item-link:hover{
		background: inherit;
		color: #4d4d4d !important;
		text-decoration: underline;
	}
	.header-cart{
		display: none;
	}
}


@media all and (max-width: 768px){
	.img-nav.logo{
		width:30px;
		height:30px;
	}
	.vertical-wordmark{
		display: none;
	}
}
