
.content-item.about-ci{
	max-height: none;
	height: auto;
}
.about-description, .about-header, .about-media{
	max-width: 500px;
	margin: auto;
	width: 100%;
}
.ci-header{
	padding: 0;
}
.about-header .ci-heading{
	margin-left:0;
	padding: 20px;
}

.about-ci .ci-text {
	margin-bottom: 25px;
	padding: 0 20px;
}


.about-media img{
	height: auto;
	width: 100%;
}
.ci-media.about-media{
	height: auto;
}
.ci-description.about-description{
	padding: 0;
}
.about-heading{
	font-weight: 100;
	font-size: var(--h1);
}

.ci-text-author{
	font-size: var(--h3a);
}