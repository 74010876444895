/*fonts*/
.ci-heading, .ci-media-label {
	font-family: var(--heading-font);
	text-transform: uppercase;
}

.ci-heading {
	font-size: var(--h2);
	font-weight: 500;
}

.ci-media-label {
	font-weight: 100;
	font-size: var(--h3);
}

.ap-text {
	font-family: var(--default-font);
	font-size: var(--dft);
	line-height: var(--h3);
}

.ci-date{
	-webkit-font-smoothing: antialiased;
}

.ap-artist{
	font-size: var(--h3);
	line-height: var(--h2);
	font-family: var(--heading-font);
}

/*display*/

.content-item {
	height: calc(100vh - var(--collapsed-nav-height));
	display: flex;
	flex-direction: column;
	overflow: hidden;
	min-height: 600px;
}

.content-item:first-of-type {
	height: calc(100vh - var(--nav-height));
}

.content-item:nth-of-type(odd) {
	background: var(--offwhite);
}

.content-item:nth-of-type(even) {
	background: var(--white);
}

.ci-header {
	order: 2;
	padding: 0 20px;
}

.ci-heading {
	margin-left: 10px;
}

.ci-media {
	order: 1;
	height: 62vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.ci-video-wrapper {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.ci-video {
	position: static;
	width: 100%;
	height: 70vh;
}

.ci-picture {
	overflow: hidden;
	height: 100vh;
}

.ci-picture-source {
	min-height: 100%;
	min-width: 100%;
	margin-left: 50%;
	margin-top: 50vh;
	transform: translateX(-50%) translateY(-50vh);

}
.ci-picture-source.img-lg, .ci-picture-source.img-sm, .ci-picture-source.img-md, .ci-picture-source.img-dft-src{
	display: none;
}
.ci-picture-source.img-sm, .img-lg, .img-dft {}

.ci-media-label {
	margin-left: 10px;
	position: relative;
	top: -15px;
	padding: 5px 20px;
	display: block;
	align-self: flex-start;
}

.content-item:nth-of-type(odd) .ci-media-label {
	background-color: var(--offwhite);
}

.content-item:nth-of-type(even) .ci-media-label {
	background-color: var(--white);
}


.ci-description {
	order: 3;
	padding: 0 30px 30px;
}

.ci-audio-wrapper {
	order: 4;
}

.ci-audio {
	width: 100%;
}


.content-item:nth-of-type(odd) .audioplayer-wrapper {
	background-color: var(--white);
}

.content-item:nth-of-type(odd) .ap-play-btn {
	background-color: var(--offwhite);
}

.content-item:nth-of-type(even) .audioplayer-wrapper {
	background-color: var(--offwhite);
}

.content-item:nth-of-type(even) .ap-play-btn {
	background-color: var(--white);
}

.audioplayer-wrapper {
	height: 140px;
	position: relative;
	display: flex;
	align-items: center;
	transform: translateZ(0);
}

.ap-timeline {
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;
	flex-direction: column;
	overflow: hidden;
	justify-content: center;
}

.ap-playhead {
	position: absolute;
	background: var(--red);
	width: 0%;
	height: 100%;
	transition: 0.1s;
	transition-timing-function: linear;
}

.ap-text {
	position: relative;
	z-index: 2;
	margin-left: 100px;
	display: inline;
	flex: none;
}

.ap-play-btn {
	position: absolute;
	border: none;
	background-color: var(--white);
	width: 56px;
	height: 56px;
	border-radius: 50%;
	margin-left: 20px;
	z-index: 2;
}

.play-icon {
	background-image: url('images/icons/play.svg');
	background-size: 26px;
	background-repeat: no-repeat;
	background-position: center;
}
.play-icon, .ap-timeline{
	cursor: url('images/icons/hover-icon.png') 10 10, auto;
}

.pause-icon {
	background-image: url('images/icons/pause.svg');
	background-size: 26px;
	background-repeat: no-repeat;
	background-position: center;
}

.ci-pic-flex{
	display: flex;
	align-items: flex-end;
	background: var(--black);
	justify-content: center;
}
.tracklist .ci-heading{
	margin-left: 0;
}
td{
	padding-right: 15px;
}
th{
	text-align: left;
}
.compactSound__artwork.sc-media-left {
display: none;
}
.soundcloud{
position: relative;
}
.soundcloud .ci-audio{
opacity: 0;
position: absolute;
z-index: -1;
}
.ci-description, .ci-header, .ci-media-label {
max-width: 960px;
margin: auto;
}
.tracklist{
order: 4;
}
.nomaxheight{
	height: auto !important;
	max-height: none !important;
}


.nomaxheight .ci-media{
	max-height: 75vh;
}

@media all and (max-width: 460px){
	.nomaxheight .ci-media{
		max-height: 38vh;
	}
}

.tracklist{
font-size: 1rem;
margin: 20px auto;
}

.body-wrapper{
	overflow: hidden;
	position: relative;
	min-width: 250px;
	min-height: 100vh;
}
