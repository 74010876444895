/*fonts*/
.footer-details, .footer-details a{
	color: var(--white);
}

.footer-details:nth-of-type(2) a, .footer-details:nth-of-type(2) .footer-details-heading{
	color: var(--black);
}

.footer-header, .footer-header h1, .footer-list-item-link {
	font-family: var(--heading-font);
	font-size: var(--h3a);
	font-weight: lighter;
	text-transform: uppercase;

	color: var(--white);
}

.footer-details-heading {
	font: normal normal 100 var(--dft) var(--default-font);
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

.copywrite{
	font-family: var(--heading-font);
	font-size: var(--heading);
	font-weight: normal;
	text-transform: uppercase;
}

/*display*/

.footer{
	display: flex;
	flex-direction: column;
}

.footer-details-wrapper{
	display: flex;
	flex-flow: column nowrap;
}

.footer-details:first-of-type{
	background: var(--red);
}

.footer-details:nth-of-type(2){
	background: var(--yellow);
}

.footer-details:nth-of-type(3){
	background: var(--green);
}

.footer-header{
	background: var(--blue);
	display: flex;
	flex-flow: row wrap;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}
.footer-header > h1{
	padding: 26.8px 0;
}

.footer-details-heading{
	padding: 20px 20px 0;
	margin-bottom: 0;
}

.footer-details-list{
	padding: 0 0 20px 0;
}

.copywrite{
	padding: 20px;
	background: var(--offwhite);
	margin: 0;
}

/*interactions*/

.footer-header:hover{
	cursor: pointer;
}
.footer-list-item:hover, .footer-list-item:hover a{
	color: var(--white);
	background: var(--black);
	transition: all 0.3s;
}


.footer-placeholder{
	height: 190px;
	overflow: hidden;
	position:relative;
}

.footer{
	position:absolute;
	width: 100%;
	z-index: 4;
	top: calc(100% - 146px);
	transform: translateZ(0);
	transition: top 0.5s, transform 0.5s;
	transition-timing-function: cubic-bezier(0.79, 0.11, 0.37, 0.85);
}
.footer-details-wrapper.open{
	max-height: calc(100vh - 150px);
	overflow: scroll;
}
.copywrite{
	position: relative;
	z-index: 5;
}

.footer.details-open{
	transform: translateY(-100%) translateZ(0);
	top: calc(100% - 66px);
}

.footer-list-item-link{
	margin-left: 20px;
	display: block;
}
.mobile-copywrite{
	display: block;
}
.desktop-copywrite{
	display: none;
}

@media all and (min-width: 769px){
	.footer-header{
		display: none;
	}
	.footer-details-wrapper{
		flex-flow: row nowrap;
	}
	.footer-details {
		flex: 1;
		max-width: 300px;
	}
	.footer-details:last-of-type{
		max-width: none;
	}
	.footer, .footer.details-open{
		top: inherit;
		bottom: 0;
		transform: translateZ(0);
	}
	.mobile-copywrite{
		display: none;
	}
	.footer-details-heading{
		padding-top: 40px;
		padding-bottom: 6px;
		font-size: 12px;
	}
	.desktop-copywrite{
		display: block;
		font-size: 12px;
		padding: 40px 30px;
	}
}

@media all and (max-width: 768px){
	.footer-placeholder{
		height: 75px;
	}
}
