.contact-form input, .contact-form textarea, .contact-form button{
    font-family: var(--default-font);
    font-size: var(--subsubheading);
    color: var(--black);

    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-form h1{
    font-size: var(--big);
    text-transform: uppercase;
    color: #949494;
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    margin-top:10px;
    margin-bottom: 30px;
}
.contact-form form{
    display: flex;
    flex-direction: column;
}

.contact-form input, .contact-form textarea {
    width: 400px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--black);
    margin-bottom: 10px;
    background: transparent;
    padding: 10px 10px 4px 0;
    outline: none;
    caret-color: var(--green);
}
.contact-form label{
    margin-bottom: 20px;
}
.contact-form label:last-of-type{
    margin-bottom: 50px;
}

.contact-form button{
    background: none;
    padding: 18px 50px;
    text-transform: uppercase;
    border: 2px solid var(--green);
    color: var(--green);
    align-self: flex-end;
    transition: all 0.3s;
}
.contact-form button:hover{
    cursor: var(--cursor) 10 10, auto;
    background: var(--green);
    color: var(--white);
    border-color: var(--black);
}

::spelling-error {
    color: var(--red);
    text-decoration-color: var(--red);
    text-decoration-line: underline;
}