/*fonts/text*/

body {
	font-family: var(--default-font);
	font-size: var(--dft);
	color: var(--black);
}

a {
	text-decoration: none;
}

.btn {
	font-size: var(--dft);
}

.inpt {
	font-size: var(--dft);
}

.select {
	font-family: var(--default-font);
}


/*display*/
p, figure, figcaption, h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	scroll-behavior: smooth;
	position: relative;
	background-color: var(--white);
}

ul, li {
	list-style-type: none;
	display: block;
	margin: 0;
	padding: 0;
}

.btn {
	-webkit-appearance: none;
	background: none;
	border: none;
	padding: 1.5rem;
}

.hidden {
	display: none !important;
}

.sr-hidden, .hamburger-sr-hidden {
	position: absolute;
	z-index: -1;
	font-size: 1px;
	color: transparent;
}

.main-content {
	min-height: 85vh;

}

.inpt {
	padding: 1rem;
	border: none;
	font-size: var(--h2);
	text-transform: uppercase;
	font-family: var(--heading-font);
	background: var(--white);
	height: 50px;
	box-sizing: border-box;
	font-weight: 500;
}

.radio-label {
	display: inline-flex;
}

.select {
	-webkit-appearance: none;
	-moz-appearance: none;
	border: 0;
	border-bottom: 2px solid var(--black);
	border-radius: 0;
	font-size: 20px;
	text-transform: uppercase;
	padding-left: 0;
	outline: none;
	background-color: transparent;
}

.select:focus{
	border-bottom-color: var(--green);
}


.select::before {
	content: ' ';
	/*background-image: url('/images/icons/chevron.svg');*/
	width: 30px;
	height: 30px;
	background: black;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	background-size: contain;
	background-position: center;
}

.radio {
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 25px;
	height: 25px;
	background: var(--white);
	border-radius: 50%;
	border: 1px solid black;
	display: inline-table;
}

.radio::after {
	display: table-cell;
	content: ' ';
}

.radio:checked::after {
	border-radius: 50%;
	background: var(--black);
	transform: scale(0.5);
}

.full-width-input-form > label, .full-width-input-form > div > label {
	display: block;
}


.underline{
	text-decoration: underline;
}

.no-min-height{
	min-height: 0 !important;
}
.no-min-width{
	min-width: auto !important;
	width: auto;
}
.max-100{
	max-height: 100%;
	max-width: 100%;
}

.main-header{
	text-align: center;
	text-transform: uppercase;
	padding: 20px;
	font-family: var(--heading-font);
	font-size: var(--big);
}

.ci-standalone-text{
	min-height: 300px;
	display: flex;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	flex-direction: column-reverse;
	justify-content: center;
}
.ci-standalone-text > .ci-header{
	padding-left: 0;
}
.ci-standalone-text > .ci-header > .ci-heading {
	margin-left: 0;
}

.ci-standalone-text .ci-text{
	margin-bottom: 5px;
	margin-top: 5px;
}

