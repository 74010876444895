/*fonts*/

.event-date, .event-location{
	font-size: var(--dft);
	font-family: var(--default-font);
	font-weight: 100;
}

.event-name, .event-item-details{
	font-size: var(--h1);
	font-family: var(--heading-font);
}

.event-name{
	font-weight: 500;
}

.event-item-details{
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}


.event-item{
	position: relative;
	cursor: url('images/icons/hover-icon.png') 10 10, auto;
}

.event-item, .ed-item{
	background: var(--offwhite);
	padding: 30px;
	padding-bottom: 50px;
}

.event-item.open{
	padding-bottom: 30px;
}

.event-item-details{
	position: relative;
	max-height: 0px;
	transition: all 0.3s;
	overflow: hidden;
}

.event-item-details.open{
	position: relative;
	max-height: 800px;
}

.event-item-header{
	display: flex;
	flex-direction: column-reverse;
	position: relative;
}

.ed-performers{
	font-weight: 100;
	-webkit-font-smoothing: antialiased;
	font-size: var(--h2);
}

.ed-description-wrapper{
	margin-top: 20px;
}
.ed-description-wrapper, .event-detail-header {
	max-width: 960px;
	margin: auto;
}

.ed-name,
.ed-performers,
.ed-date,
.ed-location,
.ed-time{

}
.ed-date{
	order: -1;
}


.event-detail-header{
	display: flex;
	flex-direction: column;
}

.event-item:nth-of-type(odd){
	background: var(--white);
}

.event-item.open .event-item-header:before{
	transition-delay: 0s;
	opacity: 0;
	transition: opacity 0s;
}

.event-item-header:before{
	content: '+';
	display: block;
	position: absolute;
	bottom: -30px;
	opacity: 1;
	transition: opacity 0.1s;
	transition-delay: 0.3s;
}

.event-name, .event-date, .event-performers{
	text-transform: uppercase;
}

.today{
	background: var(--green)!important;
	color: var(--white)!important;
}
.future{
	background: var(--blue)!important;
	color: var(--white)!important;
}



.event-details-btn{
	display: inline-block;
	border: 1px solid black;
	width: 80px;
	padding: 0.75rem 0;
	text-align: center;
	margin-top: 20px;
}

.ticket-status{
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	margin: 0 30px;
	border-bottom: 1px solid black;
}

.ticket-status:last-of-type{
	border-bottom: none;
}

.ticket-statuses{
	margin-bottom: 50px;
}

.available, .available a{
	color: var(--green);
}

.ed-pic{
	width: 100%;
	max-height: 100vh;
	overflow: hidden;
	display: block;
}

.ed-pic-source{
	max-width: 50%;
	max-height: 100%;
	margin: auto;
	display: block;
}
