.consent{
	padding: 20px;
	position: sticky;
	bottom: 0;
	z-index: 100000;
	background: var(--white);
	border-top: 2px solid var(--red);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.consent-choice{
	margin-top: 20px;
	display: flex;
	width: 60%;
	justify-content: space-between;
}
.consent .button{
	margin-right: 20px;
}

.consent p:not(:first-of-type){
	margin-top: 10px;
}